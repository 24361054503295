.background {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0, 21, 36);
  background: linear-gradient(
    163deg,
    rgba(0, 21, 36, 1) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgba(0, 138, 255, 1) 100%
  );
  z-index: -1;
}
