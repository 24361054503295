.avatar {
  width: 90%;
  max-width: 500px;
  justify-self: flex-end;

  @media only screen and (max-height: 500px) {
    width: initial;
    height: 75vh;
  }

  @media only screen and (max-width: 750px) {
    height: 70%;
    width: initial;
    justify-self: initial;
    align-self: end;
  }
}
