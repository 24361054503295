.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 14px;
  background: black;
  height: 4vh;
  min-height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding: 0 24px;

  @media only screen and (max-width: 750px) {
    padding: 8px;
    flex-direction: column;
  }

  .copyright {
    display: flex;
    gap: 8px;

    .mail {
      font-weight: normal;
    }
  }

  .links {
    display: flex;
    gap: 8px;
    font-weight: normal;

    & a:link,
    & a:visited {
      color: white;
    }
  }
}
