.root {
  justify-self: start;
  width: calc(100% - 30px);

  .title {
    font-size: clamp(2.5rem, -0.875rem + 8.333vw, 3.5rem);
    font-family: 'Encode Sans SC';
  }

  .subtitle {
    font-size: clamp(2rem, -0.875rem + 4.333vw, 2.5rem);
  }

  @media only screen and (max-width: 750px) {
    text-align: center;
    align-self: baseline;
    justify-self: center;
  }
}
