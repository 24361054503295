.root {
  height: 100%;

  .main {
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-items: center;
    gap: 20px;

    @media only screen and (max-width: 750px) {
      grid-template-columns: 100%;
      grid-template-rows: 50% 50%;
    }
  }
}
