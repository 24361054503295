@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+SC:wght@500&family=Encode+Sans:wght@300&display=swap');

html,
#root {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;

  font-family: 'Encode Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  color: #e8e8e8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
